import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={8}
    s3Path={`portfolio/babies/3/`}
  />
)

export default Showcase;
